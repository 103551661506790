import React from "react";
// import { Global, Collection, Jobs } from "@sourceflow-uk/sourceflow-sdk";
// import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import metaObject from "@/.sourceflow/metadata.json";
// import sourceflowEnums from "@/sourceflow.enums";
import ReadyCTABanner from "components/ReadyCTABanner";
import SubBanner from "@/components/SubBanner";
import LatestJobs from "components/LatestJobs";
import SF_LatestTestimonials_001 from "components/SF_LatestTestimonials_001";
import ImageWithTextSlanted from "components/FocusConsultant";
import AccordionFAQ from "@/components/AccordionFAQ";
import OtherSpecialisms from "@/components/OtherSpecialisms";

import { router } from "@/routes";
import { useLocale } from "@/hooks/useLocale";


/**
 *
 * @param global
 * @param meta
 * @returns {JSX.Element}
 * @constructor
 */
export default function JobsByLocation({
    global,
    meta,
    page,
    jobs,
    teachers,
    testimonials
}) {
    const locale = useLocale();

    return (
        <>
            {/* <SourceFlowHead
                metaObject={metaObject}
                title={meta.title}
                description={meta.description}
                canonical={meta.canonical}
            /> */}
            <div className="bg-light-white">
                <SubBanner
                    global={global}
                    pathPrefix={`jobs_by_location.${page.url_slug}.jobs_by_location.page.sub_banner`}
                    heading={`${page.title}`}
                    content={<p>Looking for a new teaching role in the {page.title} area? Tradewind helps educators just like you to find their ideal classroom job.</p>}
                    breadcrumb_title={<><span>Teaching Jobs</span><span className="active">{page.title}</span></>}
                    wave_color={`light-grey`}
                    cta_title_1={`View Jobs in ${page.title}`}
                    cta_link_1={`/jobs#/${page.title.toLowerCase().replace(/\s+/g, '-')}`}
                    editable_heading={false}
                />
                <LatestJobs
                    global={global}
                    pathPrefix={`jobs_by_location.${page.url_slug}.page.latest_jobs`}
                    heading={`Latest Jobs`}
                    post={jobs}
                    with_swiper={true}
                    heading_tag={`h2`}
                    button_cta_title={`Search Jobs`}
                    button_cta_link={`/jobs#/${page.title.toLowerCase().replace(/\s+/g, '-')}`}
                />
                {page.faq?.length >= 1 && (
                    <AccordionFAQ
                        global={global}
                        pathPrefix={`jobs_by_location.${page.url_slug}.page.accordion_faq`}
                        post={page.faq}
                        content={`FREQUENTLY ASKED QUESTIONS`}
                        heading={`Mi sodales libero ut tellus venenati`}
                        button_title_cta={`View all FAQs`}
                        button_link_cta={`/`}
                        className={`jobs-by-location`}
                    />
                )}

                {page.url_slug === 'teaching-jobs-in-london' ? <OtherSpecialisms
                    global={global}
                    pathPrefix={`teaching_jobs.${page.url_slug === "exam-invigilator-jobs" ? `${page.url_slug}.new` : page.url_slug}.page.other_specialisms`}
                    heading={`Find Primary Teaching jobs in your area`}
                    post={teachers}
                    className="teaching-jobs"
                    content={
                        <>
                            <ul>
                                <li><a href="/primary-teacher-jobs-in-manchester">Manchester</a></li>
                                <li><a href="/primary-teacher-jobs-in-birminghim">Birminghim</a></li>
                                <li><a href="/primary-teacher-jobs-in-sheffied">Sheffield</a></li>
                                <li><a href="/primary-teacher-jobs-in-liverpool">Liverpool</a></li>
                                <li><a href="/primary-teacher-jobs-in-preston">Preston</a></li>
                                <li><a href="/primary-teacher-jobs-in-st-albans">St Albans</a></li>
                                <li><a href="/primary-teacher-jobs-in-nottingham">Nottingham</a></li>
                            </ul>
                        </>
                    }
                /> : ''}
                {/* <ImageWithTextSlanted
                global={global}
                pathPrefix={`jobs_by_location.${page.url_slug}.page.image_with_text_slanted.1`}
                heading={`Hiring for Teaching Jobs in ${page.title}`}
                content={
                    <>
                        <p>Whether you’re looking for a role in a mainstream school or a specialist SEN school, we’re here to assist you. At Tradewind, we’re passionate about helping Teachers in Hull to find work in academies and schools in the Yorkshire area and beyond. Since opening in 2015, our Hull office has placed teachers in short and long-term supply placements, permanent positions and daily supply roles.</p>
                        <p>Whether you’re an experienced teacher seeking a new senior role, or an ECT trying to get onto that crucial first step on your career ladder, we’ve got you covered. You’ll be assigned a consultant to personally assist you in your search and help you to secure an education job that suits your unique skills and goals.</p>
                    </>
                }
                button_cta_title={`View Jobs in ${page.title}`}
                button_cta_link={`/jobs`}
                imageURL={`/images/placeholders/hiring-teacher-jobs-placeholder.png`}
                className="style-1 jobs-by-location-1 bg-sky-blue flip-column pt-3"
                heading_tag={`h3`}
                wrapper_align={`start`}
            // label_text={`Name Surname, Position`}
            // label_image={`/images/placeholders/tnc-logo.png`}
            />
            <ImageWithTextSlanted
                global={global}
                pathPrefix={`jobs_by_location.${page.url_slug}.page.image_with_text_slanted.2`}
                heading={`Why Work With Tradewind?`}
                content={
                    <>
                        <p>When you work with Tradewind, you’ll benefit from decades of education recruitment experience. We’ve been supporting teachers and schools with their career and staffing needs for a very long time - and based on feedback from our teaching staff, we’re pretty good at it!</p>
                        <p>We’re proud of the strong relationships we’ve built with schools and academies over the years, and these close bonds mean that we can recommend teachers to roles that truly match their skills, experience and location.</p>
                        <p>As a repeated Sunday Times Top-100 employer, you can count on us to provide you with a seamless hiring experience. Don’t just take our word for it though, there’s a reason 94% of our teachers would refer us to a friend.</p>
                    </>
                }
                button_cta_title={`Read our Reviews`}
                button_cta_link={`/`}
                imageURL={`/images/placeholders/why-work-with-tradewind-placeholder.png`}
                className="style-1 jobs-by-location-2 bg-green bg-wave-1"
                heading_tag={`h3`}
                wrapper_align={`start`}
            // earn_style={true}
            // corner_subtitle={
            //     <>
            //         Earn <br />£200!
            //     </>
            // }
            />
            <SF_LatestTestimonials_001
                global={global}
                pathPrefix={`jobs_by_location.${page.url_slug}.page.SF_LatestTestimonials_001`}
                heading={`5 star reviews in ${page.title}`}
                heading_tag={`h2`}
                swiperID={`latest_testimonials`}
                className="jobs-by-location pt-5"
                post={testimonials}
            />
            <ImageWithTextSlanted
                global={global}
                pathPrefix={`jobs_by_location.${page.url_slug}.page.image_with_text_slanted.3`}
                heading={`Our ${page.title} Office`}
                content={
                    <>
                        <p>Our Hull team is conveniently located in the Salter House building, right in the heart of the city. They have strong partnerships with nurseries and schools all over East Yorkshire and are looking for teachers like you to fill open vacancies.</p>
                        <p>To start your registration or make a booking, feel free to contact the team today. </p>
                        <p>
                            If you’d like to visit our office, our Hull address is: <br />
                            Tradewind Recruitment Hull, Studio 4,<br />
                            Avenue House, 157 High St, <br />
                            Hull, HU1 1NQ
                        </p>
                        <p>Or, if you’d prefer to discuss our services with the team, you can contact us on:</p>
                        <p>Telephone: <a href="tel:01482238878">01482238878</a><br />Email: <a href="mailto:hull@twrecruitment.com">hull@twrecruitment.com</a></p>
                    </>
                }
                button_cta_title={`Contact ${page.title} Office`}
                button_cta_link={`/`}
                imageURL={`/images/placeholders/jobs-by-location-placeholder-1.png`}
                className="style-1 jobs-by-location bg-sky-blue flip-column"
                heading_tag={`h4`}
                label_text={``}
                label_image={``}
                wrapper_align={`start`}
            />
            <div className="my-5 pt-5"></div> 
            <ReadyCTABanner
                global={global}
                pathPrefix={`jobs_by_location.${page.url_slug}.jobs_by_location.page.ready_cta_banner`}
            />
            */}
            </div>
        </>
    );
}

/**
 *
 * @returns {Promise<{props: {meta: {description: string, canonical: string, title: string}, jobs: *, global}}>}
 */

// export async function getStaticProps({ params: { url_slug } }) {
//     const global = (await Global()).toJson();

//     const slug = url_slug.join('/')
//     const jobs = (await Jobs())
//         // .filter(function (job) {
//         //     return job.categories
//         //         // Pull all jobs except internal category
//         //         .find((cat) => cat.id != "584b0a68-30f2-4a0e-9380-f41bd9ed9be5");
//         // })
//         .filter(function (job) {
//             return (
//                 (new Date(job?.expires_at)) >= (new Date())
//             )
//         })
//         .getItems()
//         .slice(0, 6);;
//     const testimonials = (await Collection(`testimonials`)).getItems().slice(0, 5);

//     const page = (await Collection("jobs_by_location")).getJsonItem(
//         slug,
//         "url_slug"
//     );

//     const routerKey = {
//         en: router["jobs_by_location"]["en"].replace("[url_slug]", page.url_slug),
//     };

//     return {
//         props: {
//             global,
//             jobs,
//             testimonials,
//             meta: {
//                 title: `Teaching Jobs at ${page.title} | Tradewind Recruitment`,
//                 description: `${sourceflowEnums.seo.genericDescription}`,
//                 // canonical: sourceflowEnums.seo.baseURL,
//                 canonical: `${sourceflowEnums.seo.baseURL}${routerKey.en}`,
//                 // alternate: { link: router[routerKey]["en"], lang: "en" },
//             },
//             page
//         },
//     };
// }