import {
    Container,
    Row,
    Col
} from 'reactstrap';
import styles from './styles.module.scss';
import clsx from "classnames";
import PropTypes from "prop-types";
import Form from "@/components/Shared/Form";
import EditableImage from "@/components/EditableImage";
import EditableText from "@/components/EditableText";
import Link from "next/link"
import Arrow from "@/public/site-assets/svg/cursive-arrow-1.svg";
import React, { useRef, useState, useEffect } from 'react';

import { motion } from 'framer-motion';


export default function OtherSpecialisms({
    className,
    global,
    pathPrefix,
    heading,
    content,
    post
}) {
    const sectionRef = useRef(null);
    const [isVisible, setIsVisible] = useState(false);

    useEffect(() => {
        const options = {
            root: null,
            rootMargin: '0px',
            threshold: 0.1 // Adjust threshold as needed, make it smaller for more sensitive detection
        };

        const observer = new IntersectionObserver((entries) => {
            entries.forEach(entry => {
                if (entry.isIntersecting) {
                    setIsVisible(true);
                    observer.unobserve(entry.target);
                }
            });
        }, options);

        observer.observe(sectionRef.current);

        // Cleanup function
        return () => {
            observer.disconnect();
        };
    }, []);

    useEffect(() => {
        const handleResize = () => {
            const mobileDevice = window.innerWidth <= 991; // Adjust threshold for mobile devices
            // setIsVisible(!mobileDevice); // Hide motion on mobile devices
        };

        handleResize(); // Call initially
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <>
            <section ref={sectionRef} className={clsx(className, styles.root)}>
                <Container>
                    <div className='wrapper'>
                        <div className='position-relative'>
                            {heading && (
                                <EditableText
                                    content={heading}
                                    path={`${pathPrefix}.heading`}
                                    global={global}
                                    tag={`h2`}
                                    className={`h3 mb-3`}
                                />
                            )}
                        </div>
                        <div>
                            {/* {post?.length >= 1 && (
                                <div className='all-locations mt-4'>
                                    {post.map((post, index) =>
                                        <motion.div initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: index * 0.1 }}>
                                            <Link href={`/contact-us/${post.url_slug}`}>{post.title}</Link>
                                        </motion.div>
                                    )}
                                </div>
                            )} */}
                            <div className='all-items'>
                                {/* {post.map((post, index) =>
                                    <motion.div initial={{ opacity: 0, y: 50 }} animate={isVisible ? { opacity: 1, y: 0 } : {}} transition={{ duration: 0.5, delay: index * 0.1 }}>
                                        <Link href={`/`}>{post.title}</Link>
                                    </motion.div>
                                    
                                )} */}
                                <EditableText
                                    content={content}
                                    path={`${pathPrefix}.content`}
                                    global={global}
                                    tag={`div`}
                                    className={`content w-100`}
                                    html={true}
                                />
                            </div>
                        </div>
                    </div>
                </Container>
            </section>
        </>
    )
}

OtherSpecialisms.defaultProps = {
    pathPrefix: "",
    className: "",
};

OtherSpecialisms.propTypes = {
    pathPrefix: PropTypes.string,
    className: PropTypes.string,
};