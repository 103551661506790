import React from "react";
import { Global, Jobs, Collection } from "@sourceflow-uk/sourceflow-sdk";
//Page Builder Components
import useDynamicPage from "hooks/useDynamicPage";
import { getDynamicPageStaticProps } from "functions/getDynamicPageStaticProps";
import useDynamicPages from "hooks/useDynamicPages";
import { DynamicPageSeo } from "ui/DynamicPageSeo";
import { BuilderContentBlocks } from "ui/BuilderContentBlocks";
import { SourceFlowHead } from "@sourceflow-uk/sourceflow-head";
import metaObject from ".sourceflow/metadata.json";
import sourceflowEnums from "@/sourceflow.enums";
//Generic Page Components
import DefaultPageTemplate from "@/components/DefaultPageTemplate";
import SubBanner from "@/components/SubBanner";
import FooterWave from "@/public/site-assets/svg/footer-wave.svg";
import FooterWaveEdited from "@/public/site-assets/svg/footer-wave-edited.svg";
import DefaultTeachingAndTATemplate from "@/components/DefaultTeachingAndTATemplate";
import JobsByLocationTemplate from "@/components/JobsByLocationTemplate";
import DefaultReferTemplate from "@/components/DefaultReferTemplate";
import { jobSearch } from '@/functions/jobSearch';
import { router } from "@/routes";

export default function Pages({
  prefix,
  title,
  seo,
  canonical,
  data,
  isBuilder,
  global,
  meta,
  page,
  teachers,
  blogs,
  jobs,
  national_college_point_values,
  contact_us_page_faq,
  teaching_and_ta_template,
  jobs_by_location_template,
  testimonials,
}) {
  return (
    <>
      {isBuilder ? (
        <>
          <DynamicPageSeo
            {...seo}
            title={seo?.title.length > 0 ? seo.title : title}
            canonical={seo?.canonical.length > 0 ? seo.canonical : canonical}
          />
          <BuilderContentBlocks data={data} prefix={prefix} />
        </>
      ) : (
        <>
          <SourceFlowHead
            metaObject={metaObject}
            title={meta.title}
            description={meta.description}
            canonical={meta.canonical}
          />
          {teaching_and_ta_template ? (
            <DefaultTeachingAndTATemplate
              global={global}
              page={page}
              teachers={teachers}
              blogs={blogs}
              jobs={jobs}
              national_college_point_values={national_college_point_values}
              contact_us_page_faq={contact_us_page_faq}
            />
          ) : jobs_by_location_template ? (
            <>
              <JobsByLocationTemplate
                global={global}
                testimonials={testimonials}
                page={page}
                jobs={jobs}
              />
            </>
          ) : page.template === "Refer" ? (
            <>
              <DefaultReferTemplate global={global} page={page} />
            </>
          ) : (
            <>
              <section
                className={`${
                  page.url_slug === "keeping-children-safe-in-education"
                    ? "subbanner-education-bg"
                    : "bg-white"
                }`}
              >
                <SubBanner
                  global={global}
                  pathPrefix={`${page.url_slug}.page.sub_banner`}
                  heading={`${page.title}`}
                  content={
                    <p>
                      Amet eget a mauris quis dignissim lacus lectus nec.
                      Suspendisse facilisis ipsum fermentum viverra tincidunt
                      viverra felis vulputate amet.
                    </p>
                  }
                  breadcrumb_title={
                    <>
                      <span className="active">{page.title}</span>
                    </>
                  }
                  editable_heading={false}
                  waveIcon={true}
                  waveChild={
                    page.url_slug === "keeping-children-safe-in-education"
                      ? true
                      : null
                  }
                />

                <DefaultPageTemplate global={global} post={page} />
                <div className="pt-5"></div>
                {page.url_slug === "keeping-children-safe-in-education" ? (
                  <div className="footer-edited">
                    <FooterWaveEdited />
                  </div>
                ) : (
                  <div className="white footer">
                    <FooterWave />
                  </div>
                )}
              </section>
            </>
          )}
        </>
      )}
    </>
  );
}

export async function getStaticProps({ params: { url_slug } }) {
  // Adds Page Builder
  const builderSlug = url_slug.join("/");
  const builderPage = useDynamicPage(builderSlug);
  if (builderPage) {
    return getDynamicPageStaticProps({
      page: builderPage,
      url_slug: builderSlug,
    });
  }

  // Original process
  const slug = url_slug.join('/');

  let pageData = null;
  let collectionUsed = '';
  let teaching_and_ta_template = false;
  let jobs_by_location_template = false;
  let refer_remplate = false;

  try {
    // Attempt to fetch data from "pages" collection
    const pageFromPages = (await Collection("pages")).getJsonItem(slug, "url_slug"
    );

    if (pageFromPages) {
      pageData = pageFromPages;
      collectionUsed = 'pages';
    }
  } catch (error) {
    // console.error('Error fetching data from "pages" collection:', error);
  }

  // If data is not found in "pages", try fetching from "teachers"
  if (!pageData) {
    try {
      const pageFromTeachers = (await Collection("teachers")).getJsonItem(slug, "url_slug");

      if (pageFromTeachers) {
        pageData = pageFromTeachers;
        collectionUsed = 'teachers';
        teaching_and_ta_template = true;
      }
    } catch (error) {
      // console.error('Error fetching data from "teachers" collection:', error);
    }
  }


  // If data is not found in "pages", try fetching from "SEN Page Template"
  if (!pageData) {
    try {
      const pageFromSenPageTemplate = (await Collection("sen_page_template")).getJsonItem(slug, "url_slug");

      if (pageFromSenPageTemplate) {
        pageData = pageFromSenPageTemplate;
        collectionUsed = 'sen_page_template';
        teaching_and_ta_template = true;
      }
    } catch (error) {
      // console.error('Error fetching data from "teachers" collection:', error);
    }
  }

  // If data is not found in "pages", try fetching from "teaching_assistants_and_support"
  if (!pageData) {
    try {
      const pageFromTA = (await Collection("teaching_assistants_and_support")).getJsonItem(slug, "url_slug");

      if (pageFromTA) {
        pageData = pageFromTA;
        collectionUsed = 'teaching_assistants_and_support';
        teaching_and_ta_template = true;
      }
    } catch (error) {
      // console.error('Error fetching data from "teaching_assistants_and_support" collection:', error);
    }
  }

  // If data is not found in "pages", try fetching from "teachers"
  if (!pageData) {
    try {
      const pageFromJobsByLocation = (await Collection("jobs_by_location")).getJsonItem(slug, "url_slug");

      if (pageFromJobsByLocation) {
        pageData = pageFromJobsByLocation;
        collectionUsed = 'jobs_by_location';
        jobs_by_location_template = true;
      }
    } catch (error) {
      // console.error('Error fetching data from "jobs_by_location" collection:', error);
    }
  }

  // If no data found in either collection
  if (!pageData) {
    return {
      notFound: true, // Return 404 page if data is not found
    };
  }

  const jobs = (await Jobs())
    // .filter(function (job) {
    //   return job.categories
    //     // Pull all jobs except internal category
    //     .find((cat) => console.log(cat));
    // })
    .filter(function (job) {
      // Check if the job categories do not include the specific value ID
      return job.categories.every(cat =>
        cat.values.every(value => value.id !== `${pageData?.exclude_related_jobs_tag}`)
      );
    })
    .filter(function (job) {
      return (
        (new Date(job?.expires_at)) >= (new Date())
      )
    })
    .getItems()
    .slice(0, 6);

  // Define jobs based on the presence of related_jobs_keyword and related_jobs_location
  let filtered_jobs = [];
  if ((pageData?.related_jobs_keyword || pageData?.related_jobs_location)) {
    filtered_jobs = await jobSearch({
      limit: 6,
      query: `${pageData.related_jobs_keyword ? pageData?.related_jobs_keyword.toLowerCase().trim() : ""}`,
      // query: `${pageData?.related_jobs_keyword.toLowerCase().trim()}`,
      location: `${pageData?.related_jobs_location ? pageData?.related_jobs_location.toLowerCase().trim() : ""}`,
      // location: `${pageData?.related_jobs_location.toLowerCase().trim()}`,
      radius: 5,
    });
  }

  const national_college_point_values = (await Collection(`national_college_point_values`)).getItems()
  const blogs = (await Collection(`blog`)).getItems().slice(0, 3);
  const teachers = (await Collection(`teachers`)).getItems()
  const contact_us_page_faq = (await Collection(`contact_us_page_faq`)).getItems()


  //Jobs By Location Component
  const testimonials = (await Collection(`testimonials`)).getItems().slice(0, 5);

  const global = (await Global()).toJson();

  const routerKey = {
    en: router["pages.item"]["en"].replace("[url_slug]", pageData.url_slug),
  };

  return {
    props: {
      global,
      page: pageData,
      teachers,
      blogs,
      jobs: filtered_jobs.length > 0 ? filtered_jobs : "", // Conditionally assign jobs
      national_college_point_values,
      contact_us_page_faq,
      teaching_and_ta_template,
      jobs_by_location_template,
      testimonials,
      meta: {
        title: `${jobs_by_location_template ? "Teaching Jobs in " : ""}${pageData.title} | Tradewind Recruitment`,
        description: `${sourceflowEnums.seo.genericDescription}`,
        // canonical: sourceflowEnums.seo.baseURL,
        canonical: `${sourceflowEnums.seo.baseURL}${routerKey.en}`,
        // alternate: { link: router[routerKey]["en"], lang: "en" },
      },
    },
  };
}

export async function getStaticPaths() {
  // adds page builder
  const builderPaths = useDynamicPages()
    .filter((i) => !["/"].includes(i.url_slug))
    .map((i) => ({ params: { url_slug: i.url_slug.split("/") } }));

  // Original process
  let allPaths = [];

  try {
    // Fetch paths from the "pages" collection
    const pageItems = (await Collection(`pages`)).getItems()
    const pagePaths = pageItems.map(item => item.url_slug);
    allPaths = allPaths.concat(pagePaths);
  } catch (error) {
    console.error('Error fetching paths from "pages" collection:', error);
  }

  try {
    // Fetch paths from the "teaching_assistants_and_support" collection
    const sen_page_template_Items = (await Collection(`sen_page_template`)).getItems()
    const sen_page_template_Items_paths = sen_page_template_Items.map(item => item.url_slug);
    allPaths = allPaths.concat(sen_page_template_Items_paths);
  } catch (error) {
    console.error('Error fetching paths from "sen_page_template" collection:', error);
  }

  try {
    // Fetch paths from the "teaching_assistants_and_support" collection
    const taItems = (await Collection(`teaching_assistants_and_support`)).getItems()
    const taPaths = taItems.map(item => item.url_slug);
    allPaths = allPaths.concat(taPaths);
  } catch (error) {
    console.error('Error fetching paths from "teaching_assistants_and_support" collection:', error);
  }

  try {
    // Fetch paths from the "teachers" collection
    const teachersItems = (await Collection(`teachers`)).getItems()
    const teacherPaths = teachersItems.map(item => item.url_slug);
    allPaths = allPaths.concat(teacherPaths);
  } catch (error) {
    console.error('Error fetching paths from "teachers" collection:', error);
  }

  try {
    // Fetch paths from the "teachers" collection
    const jobs_by_location_Items = (await Collection(`jobs_by_location`)).getItems()
    const jobs_by_location_Paths = jobs_by_location_Items.map(item => item.url_slug);
    allPaths = allPaths.concat(jobs_by_location_Paths);
  } catch (error) {
    console.error('Error fetching paths from "jobs_by_location" collection:', error);
  }

  // Combine and format paths
  const splitPaths = [...new Set(allPaths)]  // Remove duplicates if necessary
    .map(path => ({ params: { url_slug: path.split('/') } }));

  return {
    paths: [...builderPaths, ...splitPaths],
    fallback: false,
  };
}
